import React, { useState } from 'react';
import './App.css';
import Header from './components/header';
import Main from './components/main';
import InstFollow from './components/inst_follow';
import Services from './components/services';
import Form from './components/form';
import Footer from './components/footer';
import Gallery from './components/gallery';
import Price from './components/price';

function App() {
  return (
    <>
      <div className='App'>
        <Header />
        <Main />
        <Services />
        <Price />
        <InstFollow />
        <Gallery />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default App;
