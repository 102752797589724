import React from 'react';
import './../style/gallery.css';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';

function Gallery() {
  const screenWidth = window.innerWidth;

  function renderPhoto() {
    const prefix = './images/gallery/';
    const photos = [
      `/${prefix}gallery_1.jpg`,
      `/${prefix}gallery_2.jpg`,
      `/${prefix}gallery_3.jpg`,
      `/${prefix}gallery_4.jpg`,
      `/${prefix}gallery_5.jpg`,
      `/${prefix}gallery_6.jpg`,
      `/${prefix}gallery_7.jpg`,
      `/${prefix}gallery_8.jpg`,
      `/${prefix}gallery_9.jpg`,
      `/${prefix}gallery_10.jpg`,
      `/${prefix}gallery_11.jpg`,
    ];
    return Object.entries(photos).map(([service, image]) => (
      <div
        key={image}
        className='gallery__photo'
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    ));
  }
  return (
    <section id='gallery' className='services'>
      <div className='container'>
        <div className='services__wrapper'>
          <h2 className='services__title'>Галерея</h2>
          <Slide
            arrows={false}
            slidesToShow={screenWidth > 600 ? 2 : 1}
            indicators={true}
          >
            {renderPhoto()}
          </Slide>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
