import React from 'react';
import './../style/services.css';

export default function Price() {
  return (
    <section id='price' style={{ paddingTop: '80px' }}>
      <div className='container'>
        <h2 className='services__title'>Тарифи на послуги</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '30px',
              width: '426px',
            }}
          >
            <img
              src='./images/van.svg'
              className='icon__width'
              style={{
                transform: 'scaleX(-1)',
                marginRight: '30px',
              }}
            />
            <div>
              <h4
                style={{
                  fontSize: '32px',
                  color: '#2A8353',
                  marginBottom: '10px',
                }}
              >
                Від 350 грн/год
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Мікроавтобус до 1.5 тонн
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Обʼєм до 10 м.куб
              </h4>
              <h4
                style={{
                  marginBottom: '6px',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                Мінімальне замовлення 2 години
              </h4>
            </div>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', width: '426px' }}
          >
            <img
              src='./images/price_1.png'
              className='icon__width'
              style={{
                marginRight: '30px',
              }}
            />
            <div>
              <h4
                style={{
                  fontSize: '32px',
                  color: '#2A8353',
                  marginBottom: '10px',
                }}
              >
                Від 600 грн/год
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Меблевий фургон ( гідроборт ) до 1.5 тонн
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Обʼєм до 20 м.куб
              </h4>
              <h4
                style={{
                  marginBottom: '6px',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                Мінімальне замовлення 2 години
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '30px',
              width: '426px',
            }}
          >
            <img
              src='./images/price_2.png'
              className='icon__width'
              style={{
                transform: 'scaleX(-1)',
                marginRight: '30px',
              }}
            />
            <div>
              <h4
                style={{
                  fontSize: '32px',
                  color: '#2A8353',
                  marginBottom: '10px',
                }}
              >
                Від 200 грн/год
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Вантажні послуги
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Пакування особистого майна
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Поверховність
              </h4>
              <h4
                style={{
                  marginBottom: '6px',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                Мінімальне замовлення 2 години
              </h4>
            </div>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', width: '426px' }}
          >
            <img
              src='./images/price_3.png'
              className='icon__width'
              style={{
                transform: 'scaleX(-1)',
                marginRight: '30px',
              }}
            />
            <div>
              <h4
                style={{
                  fontSize: '32px',
                  color: '#2A8353',
                  marginBottom: '10px',
                }}
              >
                Від 500 грн
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Послуги монтажника
              </h4>
              <h4
                className='text'
                style={{ marginBottom: '6px', fontSize: '14px' }}
              >
                Збірка/розбірка меблів різної комплектації
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
