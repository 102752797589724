import axios from 'axios';
const token = '5556261231:AAEluYg1EDlcKGEbF6WcbW6_JRs_o1H9BK8';

const sendMessage = async (name, phone, description) => {
  const desc = description !== undefined ? `\nОпис: ${description}` : '';
  try {
    const response = await axios.post(
      `https://api.telegram.org/bot${token}/sendMessage`,
      {
        chat_id: -4073964956,
        text: `Name: ${name} \nPhone: ${phone} ${desc}`,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default sendMessage;
