import React from 'react';
import './../style/inst_follow.css';

function InstFollow() {
  function renderPhoto() {
    const prefix = './images/inst/';
    const photos = [
      `/${prefix}inst_1.jpg`,
      `/${prefix}inst_3.jpg`,
      `/${prefix}inst_4.jpg`,
      `/${prefix}inst_5.jpg`,
    ];
    return photos.map((photo) => (
      <div
        key={photo}
        className='inst__photo'
        style={{ backgroundImage: `url(${photo})` }}
      ></div>
    ));
  }
  return (
    <section id='instagram' className='inst'>
      <h2 className='inst__title'>Слідкуйте за нами в Instagram</h2>
      <div className='inst__photos'>{renderPhoto()}</div>
      <a
        href='https://instagram.com/trusty_hands_delivery_?igshid=MzMyNGUyNmU2YQ=='
        className='inst__link'
      >
        Відкрити
      </a>
    </section>
  );
}

export default InstFollow;
