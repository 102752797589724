import React from 'react';
import './../style/services.css';

function Services() {
  function renderPhoto() {
    const prefix = './images/';
    const photos = {
      'Вивіз сміття і хламу': `/${prefix}service_1.jpg`,
      'Офісний переїзд': `/${prefix}service_2.jpg`,
      'Квартирний переїзд': `/${prefix}service_3.jpg`,
      'Перевезення речей': `/${prefix}service_4.jpg`,
      'Збір меблів': `/${prefix}service_5.jpg`,
      'Перенесення будматеріалів': `/${prefix}service_6.jpg`,
    };
    return Object.entries(photos).map(([service, image]) => (
      <div key={image} className='services__container'>
        <div className='services__photo-decorator'></div>
        <div
          className='services__photo'
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <div className='services__photo-text'>{service}</div>
      </div>
    ));
  }
  return (
    <section id='services' className='services'>
      <div className='container'>
        <div className='services__wrapper'>
          <h2 className='services__title'>Послуги</h2>
          <div className='services__photos'>{renderPhoto()}</div>
        </div>
      </div>
    </section>
  );
}

export default Services;
