import React, { useState } from 'react';
import './../style/header.css';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  function scrollToComponent(id) {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const list = (
    <List>
      <a className='header__menu-link' href='#services' onClick={toggleDrawer}>
        <ListItem button>
          <ListItemText primary='Види послуг' />
        </ListItem>
      </a>
      <a className='header__menu-link' href='#price' onClick={toggleDrawer}>
        <ListItem button>
          <ListItemText primary='Тарифи на послуги' />
        </ListItem>
      </a>
      <a className='header__menu-link' href='#instagram' onClick={toggleDrawer}>
        <ListItem button>
          <ListItemText primary='Instagram' />
        </ListItem>
      </a>

      <a className='header__menu-link' href='#gallery' onClick={toggleDrawer}>
        <ListItem button>
          <ListItemText primary='Галерея' />
        </ListItem>
      </a>

      <a className='header__menu-link' href='#contacts' onClick={toggleDrawer}>
        <ListItem button>
          <ListItemText primary='Контакти' />
        </ListItem>
      </a>
    </List>
  );

  return (
    <header className='header'>
      <div className='container'>
        <div className='header__wrapper'>
          <div className='header__menu-box'>
            <IconButton
              sx={{ color: '#fff' }}
              className='header__menu'
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Drawer anchor='top' open={open} onClose={toggleDrawer}>
            {list}
          </Drawer>
          <a href='#main' className='header__logo'>
            Trusty hands
          </a>
          <nav className='header__nav'>
            <a href='#services' className='header__nav-item'>
              Види послуг
            </a>
            <a href='#price' className='header__nav-item'>
              Тарифи на послуги
            </a>
            <a href='#instagram' className='header__nav-item'>
              Instagram
            </a>
            <a href='#gallery' className='header__nav-item'>
              Галерея
            </a>
            <a href='#contacts' className='header__nav-item'>
              Контакти
            </a>
          </nav>
          <a href='tel:380982107774' className='headre__phone'>
            +380 (98) 210 77 74
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
