import React, { useState } from 'react';
import './../style/main.css';
import { Alert, Button, Snackbar } from '@mui/material';
import sendMessage from '../telegram/telegram_api';

function Main() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    'Заявка успішно відправлена.'
  );

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const handleAlert = () => {
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const onSend = () => {
    sendMessage(name, phone)
      .then((res) => {
        setAlertMessage('Заявка успішно відправлена.');
        handleAlert();
        setPhone('');
        setName('');
      })
      .catch((error) => {
        setAlertMessage('Щось пішло не так. Спробуйте ще раз.');
        handleAlert();
      });
  };
  return (
    <section id='main' className='main'>
      <div className='container'>
        <div className='main_wrapper'>
          <div className='main__content'>
            <h2 className='main__title'>
              Trusty Hands Delivery — вантажні перевезення у місті Львів
            </h2>
            <p className='main__subtitle'>
              Відмінне вантажне обслуговування тепер доступне просто з одним
              дзвінком! Ми, команда "Trusty Hands Delivery" готові забезпечити
              швидке, ефективне та безпечне переміщення вашого вантажу в межах
              міста Львів
            </p>
          </div>
          <div className='main__form'>
            <h4 className='main__form-title'>Залишіть заявку</h4>
            <p className='main__form-subtitle'>
              Ми з вами звʼяжемось найближчим часом
            </p>
            <input
              value={name}
              className='main__form-input'
              type='text'
              placeholder='Імʼя'
              onChange={(event) => setName(event.target.value)}
            />
            <input
              value={phone}
              className='main__form-input'
              type='number'
              placeholder='Номер телефону'
              onChange={(event) => setPhone(event.target.value)}
            />
            <Button
              disabled={name.length === 0 || phone.length === 0}
              variant='contained'
              className='main__form-btn'
              onClick={onSend}
              sx={{
                backgroundColor: '#1e2923',
                '&:hover': {
                  backgroundColor: '#1e2923',
                },
              }}
            >
              Відправити
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity='success'>{alertMessage}</Alert>
      </Snackbar>
    </section>
  );
}

export default Main;
