import React, { useState } from 'react';
import './../style/form.css';
import { Alert, Button, Snackbar } from '@mui/material';
import sendMessage from '../telegram/telegram_api';

function Form() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    'Заявка успішно відправлена.'
  );
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleAlert = () => {
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const onSend = () => {
    sendMessage(name, phone, description)
      .then((res) => {
        setAlertMessage('Заявка успішно відправлена.');
        handleAlert();
        setPhone('');
        setName('');
        setDescription('');
      })
      .catch((error) => {
        setAlertMessage('Щось пішло не так. Спробуйте ще раз.');
        handleAlert();
      });
  };
  return (
    <section id='contacts' className='form'>
      <div className='container'>
        <h2 className='form__title'>Контакти</h2>
        <div className='form__container'>
          <div className='form__contacts'>
            <a href='tel:+380982107774' className='form__phone-link'>
              +380 (98) 210 77 74
            </a>
            <a href='tel:+380660028019' className='form__phone-link'>
              +380 (66) 002 80 19
            </a>
            <a
              href='https://instagram.com/trusty_hands_delivery_?igshid=MzMyNGUyNmU2YQ=='
              className='form__instagram'
            >
              Instagram
            </a>
          </div>
          <div className='form__box'>
            <input
              value={name}
              type='text'
              className='form__name input'
              placeholder='Імʼя'
              onChange={(event) => setName(event.target.value)}
            />
            <input
              value={phone}
              type='text'
              className='input'
              placeholder='Номер телефону'
              onChange={(event) => setPhone(event.target.value)}
            />
            <textarea
              value={description}
              type='text'
              className='input textarea'
              placeholder='Опис замовлення'
              onChange={(event) => setDescription(event.target.value)}
            />
            <Button
              disabled={
                name.length === 0 ||
                phone.length === 0 ||
                description.length === 0
              }
              variant='contained'
              className='form__btn'
              onClick={onSend}
              sx={{
                mt: '20px',
                backgroundColor: '#1e2923',
                '&:hover': {
                  backgroundColor: '#1e2923',
                },
              }}
            >
              Відправити
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity='success'>{alertMessage}</Alert>
      </Snackbar>
    </section>
  );
}

export default Form;
